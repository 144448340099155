<script setup lang="ts">
import { ANONYMOUS_AVATAR } from '@@/bits/user_model'
import { processedUrl } from '@padlet/vivaldi-client'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * width of the avatar
     */
    width?: number
    /**
     * height of the avatar
     */
    height?: number
    /**
     * Image source URL
     */
    src?: string | null
    /**
     * Fallback image source URL
     */
    fallbackSrc?: string
  }>(),
  {
    width: undefined,
    height: undefined,
    src: null,
    fallbackSrc: ANONYMOUS_AVATAR,
  },
)

const isErrored = ref(false)

const processedSrc = computed(() => {
  if (!props.src || isErrored.value) return props.fallbackSrc

  // for relative urls e.g. when developing locally
  if (props.src.startsWith('/')) return props.src

  return processedUrl(props.src, transforms.value)
})

const transforms = computed(() => {
  if (props.width && props.height) {
    return {
      width: props.width,
      height: props.height,
      preset: 'avatar',
    }
  }
  return {
    preset: 'avatar',
  }
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <img aria-hidden="true" :width="width" :height="height" alt="" :src="processedSrc" @error="isErrored = true" />
</template>
